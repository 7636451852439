import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill';


export default function Image({src, className, alt = ''}) {
  const data = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(src);
  });

  if (!image) {
    return null;
  }

  return (
    <Img
      className={className}
      alt={alt}
      fadeIn
      fluid={image.node.childImageSharp.fluid}
      durationFadeIn={2000}
      objectFit="cover"
      placeholderStyle={{ display: 'none' }}
      objectPosition="50% 50%"
    />
  )
}