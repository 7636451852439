import React,{ Fragment } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Hero from '../components/hero';
import ClientBadge from '../components/client-badge';
import Image from '../components/image';
import moment from 'moment';
import SEO from '../components/seo';
import { trimHtmlString } from '../utils/helpers';
import '../scss/project.scss';

export default function IndexPage() {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      allProjectsJson(sort: { order: DESC, fields: [timestamp] }) {
        edges {
          node {
            slug
            client
            description
            date
            status
            tags
            title
            thumbnail
          }
        }
      }
    }
  `);

  const renderProject = ({node: project}, index) => {
    const {
      client,
      description,
      date,
      slug,
      status,
      thumbnail,
      title
    } = project;

    return (
      <div key={index} className={`project ${status === 'new' ? 'sm:w-1/3 w-full p-4' : 'sm:w-1/4 w-full p-4'}`}>
				<Link className="project-thumb-link" to={slug}>
					<div className="project-thumb">
            <Image className="project-thumb-image"  src={thumbnail} alt={title} />
					</div>
					<div className="project-info">
						<h3 className="project-title">{title}</h3>
            <ClientBadge name={client} />
						<div className="project-date">Posted: {moment(new Date(date)).format("M[/]D[/]YYYY")}</div>
						<div
              className="project-description"
              dangerouslySetInnerHTML={{
                __html: `${trimHtmlString(description, status === 'new' ? 100 : 40 )}...`
              }}
            />
          </div>
				</Link>
      </div>
    )
  }

  return (
    <Fragment>
      <SEO title="Home" />
      <Hero />
      <div id="projects" className="mt-6 container projects">
        <div className="inner">
		      <h2 className="text-2xl font-bold">Recent Projects</h2>
          <div className="flex flex-wrap -mx-4">
            { data.allProjectsJson.edges.filter(({node: post}) => post.status === 'new').map(renderProject) }
          </div>
          <h3 className="text-2xl font-bold">Archived Projects</h3>
          <div className="flex flex-wrap -mx-4">
            { data.allProjectsJson.edges.filter(({node: post}) => post.status === 'archive').map(renderProject) }
          </div>
        </div>
      </div>
    </Fragment>
  )
}
